/* Меню: верхнее (top) и нижнее (bottom) в header */
.menu {
  $border-bottom: 5px solid var(--menu-border);
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  &__item {
    font-family: var(--ff-secondary);
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: var(--menu-item);
    position: relative;
  }

  &_bottom > &__item {
    margin-right: 8px;
  }

  &__item:last-child {
    margin-right: 0;
  }

  &__item_link {
    font-family: var(--ff-secondary);
  }

  &__item:active,
  &__item_active {
    color: var(--menu-item-active);
  }

  @media (min-width: 356px) {
    &_top > &__item {
      margin-right: 16px;
    }
  }

  @media (min-width: 768px) {
    display: flex;
    justify-content: flex-start;

    &_top > &__item {
      margin-right: 24px;
    }

    &_bottom > &__item {
      margin-right: 40px;
    }
  }
}
