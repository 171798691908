.component {
  display: flex;
  flex-direction: column;
  background-color: var(--main-bg);
  box-shadow: 0px 2px 4px var(--box-shadow-color);
  border-radius: 12px;
  padding: 32px 16px 32px 48px;
  overflow: hidden;

  &__title {
    margin-bottom: 24px;
    font-family: var(--ff-secondary);
    font-weight: normal;
    font-size: 28px;
    line-height: 38px;
    text-align: left;
    color: var(--component-title-text);
  }

  &__wrapper {
    display: flex;
    align-items: center;
    border-radius: 8px;
    min-height: 100px;
    margin-bottom: 16px;
  }
}
