:root {
  box-sizing: border-box;
}

*,
::before,
::after {
  box-sizing: inherit;
  font-family: 'Roboto', sans-serif;
}

html,
body {
  height: 100%;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
