/* Навигация */
.nav {
  display: flex;
  align-items: center;

  &__controls {
    margin-left: 56px;
    display: flex;
    align-items: center;
    color: var(--nav-item);
    fill: var(--nav-item-fill);
  }

  &__controls-item {
    margin-right: 12px;
  }

  &__controls-item:last-child {
    margin-right: 0;
  }

  &__controls-item:hover {
    cursor: pointer;
  }
}
