.x-p12 {
  padding-left: 12px;
  padding-right: 12px;
}
.x-m12 {
  margin-left: 12px;
  margin-right: 12px;
}
.x-mt16 {
  margin-top: 16px;
}
