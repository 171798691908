/*  Превью */
.preview {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;

  @media (min-width: 768px) {
    gap: 30px;
  }
}
