/* Карточка */
.card {
  width: 100%;
  min-height: 200px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 32px 24px;
  text-align: center;
  background-color: var(--card-bg);
  box-shadow: 0px 2px 4px var(--box-shadow-color);

  &__top {
    flex-grow: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &__bottom {
    max-width: 300px;
  }

  &__title,
  &__subtitle {
    white-space: normal;
    overflow-x: hidden;
    text-overflow: ellipsis;

    @media screen and (min-width: 600px) {
      white-space: pre;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }

  &__title {
    margin-top: 16px;
    font-weight: 500;
    font-size: 21px;
    line-height: 25px;
    color: var(--card-title);
  }

  &__subtitle {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: var(--card-subtitle);
  }
}
