.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.25s;
  z-index: 111;
  background-color: var(--modal-context-bg);

  &_open,
  &:target {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }

  &__window {
    position: absolute;
    max-width: 428px;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 30px 40px 33px;
    background-color: var(--modal-bg);
    border-radius: 8px;
    &_preview {
      position: relative;
      top: 0;
      left: 0;
      max-width: 200px;
      padding: 1em;
      transform: none;
      border: 1px solid var(--border-color);
      pointer-events: none;
    }
  }

  &__close {
    position: absolute;
    top: 12px;
    right: 12px;
  }

  &__svg-icon {
    color: var(--modal-icon);
    width: 24px;
    height: 24px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 14px;
    font-family: var(--ff-secondary);

    &-title {
      font-family: inherit;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: var(--modal-body-title);
    }

    &-text {
      font-family: inherit;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: var(--modal-body-text);
    }
  }
}
