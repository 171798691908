.skeleton {
  display: flex;
  flex-direction: column;
  max-width: 576px;
  height: 192px;
  padding: 24px 24px 32px;
  border: 1px solid var(--border-color);
  border-radius: 8px;
  background: var(--main-bg);

  &__top {
    display: flex;
    align-items: center;
    & .skeleton__lines {
      margin-left: 16px;
    }
  }

  &__bottom {
    margin-top: 24px;
  }

  &__down {
    display: flex;
    flex-direction: column;
  }

  &__circle {
    flex-shrink: 0;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: var(--skeleton-bg);
  }

  &__lines {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__line {
    height: 20px;
    background: var(--skeleton-gradient);

    &:last-child {
      margin-top: 8px;
    }

    &__line_40pr {
      width: 40%;
    }

    &__line_60pr {
      width: 60%;
    }

    &_100pr {
      width: 100%;
    }
  }

  &__circle,
  &__line {
    animation-name: backgroundWave;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    background: var(--skeleton-bg);
    background: var(--skeleton-gradient);
    background-size: 200%;
  }
}
