.avatar {
  width: 150px;
  height: 150px;
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}
