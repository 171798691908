.tooltip {
  position: relative;
  display: inline-block;
  white-space: nowrap;

  &__icon {
    width: 24px;
    height: 24px;
    color: var(--tooltip-icon);
    cursor: pointer;
    pointer-events: all;
  }

  &__help {
    position: absolute;
    display: inline;
    max-width: 300px;
    padding: 13px 21px;
    top: -150%;
    left: 50%;
    transform: translate(-50%, -10px);
    color: var(--tooltip-active);
    background-color: var(--primary);
    box-shadow: 0px 2px 4px var(--box-shadow-color);
    border-radius: 10px;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.25s ease-out;
  }

  &_active .tooltip__help,
  &__icon:hover + .tooltip__help {
    opacity: 1;
  }

  &__text {
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -8px;
      border-width: 8px;
      border-style: solid;
      border-color: var(--primary) transparent transparent transparent;
    }
  }
}
