/* Стилизация аккордеона */
.accordion {
  border: 1px solid var(--border-color);
  border-radius: 0.5em;
  padding: 1em 1.5em;
  font-family: var(--ff-secondary);
  font-size: 1rem;
  line-height: 1.5;
  background-color: var(--main-bg);

  &__summary {
    display: block;
    outline: none;
    height: 1.5em;
    &:focus {
      border-radius: 2px;
      padding: 2px 4px;
      box-shadow: 0px 0px 2px 2px var(--outline-color);
    }
    &::after {
      clear: both;
    }
    &::-webkit-details-marker {
      display: none;
    }
  }

  &__title {
    float: left;
    color: var(--accordion-title);
  }

  &__icon {
    float: right;
    color: var(--accordion-text);
    margin-left: auto;
    transition: transform 0.35s ease-out;
  }

  &__text {
    color: var(--accordion-text);
  }

  &[open] &__icon {
    transform: rotate(180deg);
    transition: transform 0.35s ease-out;
  }

  &[open] &__summary ~ * {
    animation: sweep 0.75s ease-in-out;
  }
}
